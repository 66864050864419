export default {
  name: 'Home',
  data() {
    return {};
  },
  metaInfo: {
    title: '智者行一',
    meta: [{
      charset: 'utf-8'
    }, {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
    }]
  }
};